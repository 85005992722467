import React, { useRef } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

import EmailEditor from 'react-email-editor';
import sample from './sample.json';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 5px);
`;

const Bar = styled.div`
  flex: 1;
  background-color: #61dafb;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 40px;
	.left {
		flex: 1;
		display: flex;
		button {
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #fff;
		border-radius: 4px;
    color: #1f61a9;
    border: 0px;
    max-width: 150px;
    cursor: pointer;
			
		}
	}
  h1 {
    font-size: 16px;
    text-align: left;
  }
  button.right {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
		border-radius: 4px;
    background-color: #000;
    color: #fff;
    border: 0px;
    max-width: 150px;
    cursor: pointer;
  }
`;

function download(text, name, type) {
  var file = new Blob([text], {type: type});
	saveAs(file, name);
  //a.download = name;
}

const Example = (props) => {
  const emailEditorRef = useRef(null);
  const designFile = useRef(null);

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
			download(JSON.stringify(design), "template.json", "application/json;charset=utf-8");
      //console.log('saveDesign', design);
      //alert('Design JSON has been logged in your developer console.');
    });
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //console.log('exportHtml', html);
			download(html, "template.html", "text/html;charset=utf-8");
      //alert('Output HTML has been logged in your developer console.');
    });
  };

	const loadDesign = () => {
		designFile.current.click();
	}

	const onDesignChange = (e) => {
		var file = e.target.files[0];
		var reader = new FileReader();
    
		reader.onload = function(){
			var content = JSON.parse(reader.result);

     emailEditorRef.current.editor.loadDesign(content);
		}
    
    reader.readAsText(file);
	}

  const onDesignLoad = (data) => {
    //console.log('onDesignLoad', data);
		designFile.current.value = null;
  };

  const onLoad = () => {
    //console.log('onLoad');

    emailEditorRef.current.editor.addEventListener(
      'design:loaded',
      onDesignLoad
    );

    //emailEditorRef.current.editor.loadDesign(sample);
  }

  const onReady = () => {
    console.log('onReady');
  };

  return (
    <Container>
      <Bar>
				<input accept="application/json" onChange={onDesignChange} type="file" id="file" ref={designFile} style={{display: "none"}}/>
				<div className="left">
        <h1>Email Editor</h1>


        <button onClick={loadDesign}>Load Design...</button>
				</div>
        <button className="right" onClick={saveDesign}>Save Design</button>
        <button className="right" onClick={exportHtml}>Export HTML</button>
      </Bar>

      <React.StrictMode>
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      </React.StrictMode>
    </Container>
  );
};

export default Example;
